import React from 'react';
import { FinishNewsletterPopup } from '@husky-x/cc-finish-newsletter-popup';
import { CustomComponentsProvider } from '@husky-x/gatsby-theme-husky';
import { FinishNewsletterImageForm } from '@phx-husky/cc-finish-newsletter-image-form';
import { FinishProductDifferences } from '@phx-husky/cc-finish-product-differences';
import { FinishProductsCards } from '@phx-husky/cc-finish-products-cards';

import {
  FinishNewsletterPopupMock,
  FinishProductCardsMock,
  FinishProductCardsMockFR,
  FinishProductDifferencesMock,
} from './custom-components-mocks';

export const wrapRootElement = ({ element }) => (
  <CustomComponentsProvider
    list={[
      {
        key: 'FinishProductDifferences',
        component: () => <FinishProductDifferences {...FinishProductDifferencesMock} />,
      },
      {
        key: 'FinishProductCards',
        // @ts-expect-error - cardsStyle in ProdCards is coded as set of specified strings - mocks provides those strings, but guardtypes them as 'string'
        component: () => <FinishProductsCards {...FinishProductCardsMock} />,
      },
      {
        key: 'FinishProductCardsFR',
        // @ts-expect-error - cardsStyle in ProdCards is coded as set of specified strings - mocks provides those strings, but guardtypes them as 'string'
        component: () => <FinishProductsCards {...FinishProductCardsMockFR} />,
      },
      {
        key: 'FinishNewsletterPopup',
        component: () => <FinishNewsletterPopup {...FinishNewsletterPopupMock} />,
      },
      {
        key: 'custom_finish_newsletter_image_form',
        component: FinishNewsletterImageForm,
      },
    ]}
  >
    {element}
  </CustomComponentsProvider>
);
